import { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom'; // 21.12.09 SCH
import { format } from 'date-fns'; // 21.12.09 SCH
import { useAuth } from 'context/Auth'; // 21.12.09 SCH
import { listUserRentLogs, Logs1Type } from 'api/logs'; // 21.12.09 SCH
import { ReactComponent as PinDoneSVG } from 'assets/pin-done.svg';
import { ReactComponent as SortSVG } from "../../assets/icon-sort.svg";

const ReturnHistory = () => {
  const { accessToken, me } = useAuth(); // 21.12.09 SCH
  const [filter, setFilter] = useState<'all' | 'self' | 'other'>('all');
  const [sort, setSort] = useState<'ascend' | 'descend'>('descend');
  // const [activeTab, setActiveTab] = useState<number>(0);
  const { pathname } = useLocation();
  const [logs, setLogs] = useState<Logs1Type[]>();
  const filterSelf = useRef<number>(2); // 22.01.07 SCH, <2:all | 1:self | 0:other>
  const sortRef = useRef<string>('descend'); // 22.05.30 SCH

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line
  }, [pathname]);

  const fetchLogs = async () => {
    try {
      // 注意！要用「me?.id!」才能正常 work by Mike 21.12.17
      const { data } = await listUserRentLogs(
        accessToken!, 1, me?.id!,
        filterSelf.current, sortRef.current,
      );
      if (!data) {
        return 'Have not any return logs !!';
      } else {
        setLogs(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateStr: any, dateStr2: string) => {
    if (dateStr == null) {
      return dateStr2.slice(0,10); // 23.01.05 SCH
    }
    if (dateStr.indexOf('T') > 0) {
      let newDate = format(new Date(dateStr), 'yyyy-MM-dd');
      return newDate; // 22.05.29 SCH
    }
    return dateStr.slice(0,10); // 22.05.30 SCH
  };

  return (
    <div className={styles.history}>
      <div className={styles.filter}>
        <button
          className={filter === 'all' ? styles.active : undefined}
          onClick={() => {
            setFilter('all');
            filterSelf.current = 2;
            fetchLogs(); // 22.05.30 SCH
          }}
        >
          全部
        </button>
        <button
          className={filter === 'self' ? styles.active : undefined}
          onClick={() => {
            setFilter('self');
            filterSelf.current = 1;
            fetchLogs(); // 22.05.30 SCH
          }}
        >
          自己
        </button>
        <button
          className={filter === 'other' ? styles.active : undefined}
          onClick={() => {
            setFilter('other');
            filterSelf.current = 0;
            fetchLogs(); // 22.05.30 SCH
          }}
        >
          別人
        </button>
        <div className={styles.sort}>
          <button
            onClick={() => {
              setSort(sort === 'ascend' ? 'descend' : 'ascend');
              sortRef.current = sort === 'ascend' ? 'descend' : 'ascend';
              fetchLogs(); // 22.05.30 SCH
            }}
          >
            {sort === 'descend' ? '最新' : '最舊'}
            <SortSVG />
          </button>
        </div>
      </div>

      <div className={styles.list}>
        {logs?.map((item) => (
          <div className={styles.listItem} key={item.id}>
            <div className={`${styles.status} ${styles.done}`}>
              <div className={styles.startDate}>
                <span>起始日</span>
                <span>{formatDate(item.shipping_at, item.created_at)}</span>
              </div>
              <div className={styles.line}>
                <PinDoneSVG />
              </div>
              <div className={styles.expireDate}>
                <span>歸還日</span>
                <span>{formatDate(item.expired_at, item.overdue_at)}</span>
              </div>
            </div>
            <div className={styles.detail}>
              <div>
                <span>歸還地點</span>
                <span>
                  {item.channel_name}-{item.svpt_name}
                </span>
              </div>
              <div>
                <span>類別</span>
                <span>{item.rentTypeName}</span>
              </div>
              <div>
                <span>金額</span>
                <span>{item.paid_amount}</span>
              </div>
              <div>
                <span>付款方式</span>
                <span>{item.paidWayName}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <span className={styles.tip}>僅顯示最近3個月內的記錄</span>
    </div>
  );
};

export default ReturnHistory;
